exports.components = {
  "component---src-components-templates-female-care-template-jsx": () => import("./../../../src/components/templates/female-care-template.jsx" /* webpackChunkName: "component---src-components-templates-female-care-template-jsx" */),
  "component---src-components-templates-legal-template-jsx": () => import("./../../../src/components/templates/legal-template.jsx" /* webpackChunkName: "component---src-components-templates-legal-template-jsx" */),
  "component---src-components-templates-magazin-template-jsx": () => import("./../../../src/components/templates/magazin-template.jsx" /* webpackChunkName: "component---src-components-templates-magazin-template-jsx" */),
  "component---src-components-templates-proyecto-v-template-jsx": () => import("./../../../src/components/templates/proyecto-v-template.jsx" /* webpackChunkName: "component---src-components-templates-proyecto-v-template-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-500-jsx": () => import("./../../../src/pages/500.jsx" /* webpackChunkName: "component---src-pages-500-jsx" */),
  "component---src-pages-contactanos-jsx": () => import("./../../../src/pages/contactanos.jsx" /* webpackChunkName: "component---src-pages-contactanos-jsx" */),
  "component---src-pages-entre-nosotras-cuidado-femenino-jsx": () => import("./../../../src/pages/entre-nosotras/cuidado-femenino.jsx" /* webpackChunkName: "component---src-pages-entre-nosotras-cuidado-femenino-jsx" */),
  "component---src-pages-entre-nosotras-jsx": () => import("./../../../src/pages/entre-nosotras.jsx" /* webpackChunkName: "component---src-pages-entre-nosotras-jsx" */),
  "component---src-pages-guardados-jsx": () => import("./../../../src/pages/guardados.jsx" /* webpackChunkName: "component---src-pages-guardados-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-magazin-jsx": () => import("./../../../src/pages/magazin.jsx" /* webpackChunkName: "component---src-pages-magazin-jsx" */),
  "component---src-pages-magazin-nuestro-mundo-jsx": () => import("./../../../src/pages/magazin/nuestro-mundo.jsx" /* webpackChunkName: "component---src-pages-magazin-nuestro-mundo-jsx" */),
  "component---src-pages-mis-notificaciones-jsx": () => import("./../../../src/pages/mis-notificaciones.jsx" /* webpackChunkName: "component---src-pages-mis-notificaciones-jsx" */)
}

